import React from 'react'
import { Container } from 'react-bootstrap'
import './compMarketing1.css'


const CompMarketing1 = (props) => {
  return (
    <Container fluid className='marketing1'>

        <div className='d-flex justify-content-center'><img className='imgPlanMarketing1' src={props.imgPlan} alt="" /></div>
        <h2 className='planMarketing1 text-center'>{props.tituloPlan}</h2>
        <div className='mt-3 listaPlanes'>
          <div className='d-flex detalleMarketing1'>
          <div className='circleIcon'><i className="bi bi-check"></i></div>
            <span>{props.plan1}</span>  
          </div>
          
          <div className='d-flex detalleMarketing1'>
          <div className='circleIcon'><i className="bi bi-check"></i></div>
            <span>{props.plan2}</span>  
          </div>

          <div className='d-flex detalleMarketing1'>
          <div className='circleIcon'><i className="bi bi-check"></i></div>
            <span>{props.plan3}</span>  
          </div>

          <div className='d-flex detalleMarketing1'>
          <div className='circleIcon'><i className="bi bi-check"></i></div>
            <span>{props.plan4}</span>  
          </div>

          <div className='d-flex detalleMarketing1'>
          <div className='circleIcon' hidden= { props.plan5 == null ? true : false} ><i className="bi bi-check"></i></div>
            <span>{props.plan5}</span>  
          </div>
        </div>
        <a href={props.pdf} rel='noreferrer' target={'_blank'}><button className= {props.tituloPlan === "Enrédate VIP" ? 'btnMarketing2 blob-btn1' : 'btnMarketing1 blob-btn' }  >Más Información
        {props.tituloPlan  === "Enrédate VIP" ? <span class="blob-btn__inner1">
          <span class="blob-btn__blobs1">
            <span class="blob-btn__blob1"></span>
            <span class="blob-btn__blob1"></span>
            <span class="blob-btn__blob1"></span>
            <span class="blob-btn__blob1"></span>
          </span>
        </span> : 
        <span class="blob-btn__inner">
          <span class="blob-btn__blobs">
            <span class="blob-btn__blob"></span>
            <span class="blob-btn__blob"></span>
            <span class="blob-btn__blob"></span>
            <span class="blob-btn__blob"></span>
          </span>
        </span> }
        
        
        </button></a>   
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
              <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
            </filter>
          </defs>
        </svg>    

    </Container>

    
  )
}

export default CompMarketing1