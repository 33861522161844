import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import WOW from 'wowjs';

const ScrollToTop = () => {
    const { pathname } = useLocation();    

    useEffect(() => {
     //"document.documentElement.scrollTo" is the magic for React Router Dom v6
        document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [pathname]);

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, []);

    useEffect(() => {
      const handleResize = () => {
        let innerActual = window.innerWidth
        if (window.innerWidth !== window.outerWidth) {
          window.location.reload();          
        }

        // if(innerActual === window.outerWidth && innerActual > 1320 ){
        //   window.location.reload();  
        // }
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        null
    )
}

export default ScrollToTop