import React from 'react'
import { Container } from 'react-bootstrap'
import './compNosotros2.css'

import imgLinkedin from "../../img/linkedin.png"


const CompNosotros2 = (props) => {
  return (
    <Container className='nosotros2'>
        <div className='contenidoAvatar'>
            <div className='d-flex justify-content-center'>
                <img className="rounded-circle imgAvatar" alt="avatar1" width={130} height={130} src={props.imgFoto1} />
            </div>        
            <h2 className='tituloNosotros2 text-center'>{props.nombre1}</h2>
            <h4 className='puestoNosotros2 text-center'>{props.puesto1}</h4>
            <div className='d-flex justify-content-center redesNosotros'>               

                <div role={'button'} className="circleAvatar d-flex justify-content-center mx-2 align-items-center">
                    <a href={props.linkedin1} target={'_blank'} rel='noreferrer'><img src={imgLinkedin} width={23} height={23} alt=""/></a>                                
                </div>
                
            </div>
        </div>

        <div className='contenidoAvatar'>
            <div className='d-flex justify-content-center'>
                <img className="rounded-circle" alt="avatar1" src={props.imgFoto2} width={130} height={130} />
            </div>        
            <h2 className='tituloNosotros2 text-center'>{props.nombre2}</h2>
            <h4 className='puestoNosotros2 text-center'>{props.puesto2}</h4>
            <div className='d-flex justify-content-center redesNosotros'>                

                <div  role={'button'} className="circleAvatar d-flex justify-content-center mx-2 align-items-center">
                    <a href={props.linkedin2} target={'_blank'} rel='noreferrer'><img src={imgLinkedin} width={23} height={23} alt=""/></a>                              
                </div>
                
            </div>
        </div>
    </Container>
  )
}

export default CompNosotros2