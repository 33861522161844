import React from 'react'
import { Container } from 'react-bootstrap'
import CompNosotros1 from '../../components/compNosotros1/CompNosotros1'
import './nosotrosPage.css'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css"

import mision from "../../img/mision.png"
import vision from "../../img/vision.png"
import valores from "../../img/valores.png"

import imgMujer from "../../img/avatar_mujer.png"
import imgVaron from "../../img/avatar_varon.png"
import ceo from "../../img/CEO_TPX_Marlon.png"
import coo from "../../img/COO_TPX_Roberto.png"
import cmo from "../../img/CMO_TPX_Allison.png"
import cto from "../../img/CTO_TPX_Wilder.png"
import cfo from "../../img/CFO_TPX_JuanCarlos.png"
import dis_uxui1 from "../../img/IALVARINO.jpg"
import analista_proc1 from "../../img/Operaciones_EvelinVelasco.png"
import des_web from "../../img/David_Carranza.jpg"
import des_wordpress from "../../img/Tecnologia_Carlos_Porras.png"
import comm_manager from "../../img/Britney_Quiroz.png"
import analista_proc2 from "../../img/Operaciones_Andy.jpeg"
import analista_legal from "../../img/CLO_TPX_Shirley.png"

import CompNosotros2 from '../../components/compNosotros2/CompNosotros2'

const NosotrosPage = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
  <>
    <Container fluid className='seccion1-Nosotros text-center '>
      <h2 className='titulo1Nosotros '>Nosotros</h2>
      <h4 className='sub1Nosotros text-center'> Estamos dispuestos a apoyar a los emprendedores de nuestro país; transformando los 
        distintos problemas que tiene nuestra sociedad en soluciones innovadoras, viables, sostenibles
         y responsables con el medio ambiente.
      </h4>

      <Container className='compNosotros1' >
        <CompNosotros1
        imgNosotros = {mision}
        titulo = "Misión"
        desc = "Somos una organización enfocada en brindar servicios que apoyen a la transformación digital de las empresas para lograr el éxito esperado, utilizando creatividad, innovación y tecnología."
        />
      </Container>

      <Container className='compNosotros1' >
      <CompNosotros1
        imgNosotros = {vision}
        titulo = "Visión"
        desc = "En un mundo estrechamente interrelacionado por las tecnologías de información, queremos llegar a ser líder en la provisión de soluciones innovadoras de marketing digital y desarrollo de software."
        />
      </Container>

      <Container className='compNosotros1' >
      <CompNosotros1
        imgNosotros = {valores}
        titulo = "Valores"
        desc = "Somos una organización enfocada en brindar servicios que apoyen a la transformación digital de las empresas para lograr el éxito esperado, utilizando creatividad, innovación y tecnología."
      />
      </Container>
    </Container>
    <Container fluid className='seccion2-Nosotros'>
      <p className='titulo2Nosotros text-center'>Nuestro Equipo </p>

      <Carousel className='carouselNosotros' 
        responsive={responsive}
        arrows={true} 
        renderButtonGroupOutside={true}                
        infinite= {true}                
        itemClass="d-flex align-items-center justify-content-center">
        <CompNosotros2
          imgFoto1 = {ceo}
          nombre1 = "Marlon Núñez"
          puesto1 = "CEO"
          linkedin1 = "https://www.linkedin.com/in/marlon-nu%C3%B1ez-73b8911bb/"
          imgFoto2 = {coo}
          nombre2 = "Roberto Ramírez"
          puesto2 = "COO"
          linkedin2 = "https://www.linkedin.com/in/roberto-ramirez-rojas-a3968560/"        
        
        />
        <CompNosotros2
          imgFoto1 = {cmo}
          nombre1 = "Allison Bravo"
          puesto1 = "CMO"
          linkedin1 = "https://www.linkedin.com/in/allisonbravo/"
          imgFoto2 = {cto}
          nombre2 = "Wilder Canales"
          puesto2 = "CTO"
          linkedin2 = "https://www.linkedin.com/in/wildercanalessoller/"        
        
        />

        <CompNosotros2
          imgFoto1 = {cfo}
          nombre1 = "Juan Carlos Lau"
          puesto1 = "CFO"
          linkedin1 = "https://www.linkedin.com/in/juanlaupe%C3%B1a/"
          imgFoto2 = {dis_uxui1}
          nombre2 = "Ivette Alvariño"
          puesto2 = "Diseñadora UX/UI"
          linkedin2 = "https://www.linkedin.com/in/ivette-alvarino/"        
        
        />

        <CompNosotros2
          imgFoto1 = {imgVaron}
          nombre1 = "Florentino Carrión"
          puesto1 = "Diseñador UX/UI"
          linkedin1 = "https://www.linkedin.com/in/florentinocarrion/"
          imgFoto2 = {imgMujer}
          nombre2 = "Vanessa Alvariño"
          puesto2 = "Diseñadora Gráfica"
          linkedin2 = "https://www.linkedin.com/in/vanessa-alvari%C3%B1o-torres-5b25b4143/"        
        
        />

        <CompNosotros2
          imgFoto1 = {imgMujer}
          nombre1 = "Rosa Rivera"
          puesto1 = "Diseñadora Gráfica"
          linkedin1 = "https://www.linkedin.com/in/rosa-rivera-huamanricra-9a134a1a2/"
          imgFoto2 = {analista_proc1}
          nombre2 = "Evelin Velasco"
          puesto2 = "Analista de Procesos"
          linkedin2 = "https://www.linkedin.com/in/evelin-velasco-ticona-686027134/"        
        
        />

        <CompNosotros2
          imgFoto1 = {analista_proc2}
          nombre1 = "Andy Gonzáles"
          puesto1 = "Analista de Procesos"
          linkedin1 = "https://www.linkedin.com/in/andy-gonzales-67619311b/"
          imgFoto2 = {des_wordpress}
          nombre2 = "Carlos Porra"
          puesto2 = "Desarrollador WordPress"
          linkedin2 = "https://www.linkedin.com/in/carlos-antony-porras-santos/"        
        
        />
        <CompNosotros2
          imgFoto1 = {des_web}
          nombre1 = "David Carranza"
          puesto1 = "Desarrollador Web"
          linkedin1 = "https://www.linkedin.com/in/david-carranza-inga"
          imgFoto2 = {imgMujer}
          nombre2 = "Joyce Salgado"
          puesto2 = "Ejecutiva de Ventas"
          linkedin2 = "https://www.linkedin.com/in/joyce-salgado-b17245191/"        
        
        />
        
        <CompNosotros2
          imgFoto1 = {analista_legal}
          nombre1 = "Shirley Torres"
          puesto1 = "Analista Legal"
          linkedin1 = "https://www.linkedin.com/in/shirley-torres-candiotti-6598251bb/"
          imgFoto2 = {comm_manager}
          nombre2 = "Britney Quiroz"
          puesto2 = "Community Manager"
          linkedin2 = "https://www.linkedin.com/in/britney-quiroz-07ba80228"       
        
        />

                
      </Carousel>

    </Container>


  </>
    
  )
}

export default NosotrosPage